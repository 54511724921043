<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <!--begin::Content-->
                <div class="flex-row-fluid ">
                    <div class="row">
                        <div class="col-lg-12">
                            <Widget13 corpId></Widget13>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Widget13 from "@/view/content/widgets/admin/Widget13.vue";

export default {
    name: 'corporateedit',
    components: {
        Widget13
    },
}
</script>
